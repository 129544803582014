<template>
    <div class="container all-parteners">
       <div class="text-center">
        <div class="row all-parteners__logo" >
          <div  class="col-lg-3 col-md-6 col-sm-12 all-parteners__logo__item" v-for="value in partners" :key="value">
            <img
            :src="'https://test.it-tp.xyz/' + value.logo" height="150"
            />
        </div>
        </div>

       </div>
    </div>
</template>
<script>
import {baseUrl} from './../../../service';
import axios from 'axios'
export default{
    data(){
        return{
        partners:[]
        }
    },
    mounted(){
    axios
      .get(`${baseUrl}/get-settings`)
      .then(response => {
        this.partners = response.data.data.partners;
      })
  }
}
</script>
<style scoped lang="scss">
.all-parteners__logo__item{
  margin-top: 30px;
}
</style>
