<template>
  <section class="info my-5 py-3">
    <div class="row flex-row-reverse">
      <slot></slot>
    </div>
  </section>
</template>

<script>
export default {
  name: "info-section-right",
};
</script>
