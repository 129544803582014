<template>
  <home-component />
</template>

<script>
import HomeComponent from "@/components/home/Home.vue";

export default {
  name: "home-page",
  components: { HomeComponent },
};
</script>
