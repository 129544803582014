<template>
  <p class="mb-5 text-center">{{ desc }}</p>
</template>

<script>
export default {
  name: "description-component",
  props: ["desc"],
};
</script>
