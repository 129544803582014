<template>
  <div class="contact-details text-center">
    <div class="adress mb-2">
      <p><strong>Email</strong></p>
      <p>{{email}}</p>
    </div>
    <div class="adress mb-2">
      <p><strong>Fax</strong></p>
      <p>{{fax}}</p>
    </div>

    <div v-for="(item,index) in addresses" :key="index" class="adress mb-2">
      <h3 class="country">{{item.headquarter}}</h3>
      <p><strong>Phone</strong></p>
      <p>{{item.phone}}</p>
      <p><strong>Fax</strong></p>
      <p class="my-2">{{item.fax}}</p>
      <p><strong>Adress</strong></p>
      <p>{{item.address}}</p>
    </div>
    <a
      href="https://goo.gl/maps/sYHpGz6txLvRLbBr9"
      class="py-2 px-4 mt-3 btn location"
      target="_blank"
    >
      <i class="fa-solid fa-location-pin text-white mx-1"></i>
      Head office location</a
    >
  </div>
</template>

<script>
import {baseUrl} from './../../../service';
import axios from 'axios'

export default {
  name: "contact-details",
  data(){
    return{
      email:'',
      fax:'',
      addresses:[]

    }
  },
  mounted(){
    axios 
      .get(`${baseUrl}/get-settings`)
      .then(response => {
        //this.values = response.data
        this.email = response.data.data.settings.email;
        this.fax = response.data.data.settings.fax;
        this.addresses = response.data.data.addresses
        console.log(response.data);
        console.log(response.data.data.settings)
      })
    
  }
};
</script>

<style scoped>
.country {
  color: var(--red-color);
}
.button {
  background-color: var(--red-color);
}
h4 a {
  color: var(--red-color);
}
p {
  margin-bottom: 0;
}
.location {
  background-color: var(--red-color);
  color: #fff;
  border-color: var(--red-color);
  outline-color: var(--red-color);
  font-weight: bold;
  font-size: 18px;
}
.location:hover {
  color: #fff;
}
</style>
