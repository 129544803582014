<template>
  <section class="intro text-center">
    <!-- <h2 class="text-capitalize mb-4">technology partner</h2>
    <h3 class="mb-4">is your trusted source in IT services and support</h3>
    <p class="intro-desc"></p>
  -->
  </section>

</template>

<script>
export default {
  name: "introduction-component",

 
};
</script>
