<template>
  <section class="blog mt-5 pt-5">
    <div class="row justify-content-around">
      <div
        class="card bg-transparent blog__card col-12 col-md-4 border-0"
        v-for="(blog, index) in blogs"
        :key="index"
      >
        <template v-if="index < 3">
          <router-link :to="`/blog/${blog.id}`">
            
             <img
           :src="'https://test.it-tp.xyz/' + blog.image"
              class="card-img-top image-blog"
              alt="Ejaf ERP"
            /> 
          </router-link>
          <div class="card-body px-0">
            <h5 class="card-title">
              <router-link :to="`/blog/${blog.id}`"
                >{{ blog.title }}
              </router-link>
            </h5>
            <small class="my-3 d-block"
              >{{ blog.created_at }}
              <strong><router-link to="/blog">Blog</router-link></strong></small
            >
            <p class="card-text">
              {{ blog.description }}
            </p>
            <router-link :to="`/blog/${blog.id}`">Read More</router-link>
          </div>
        </template>
      </div>
    </div>
    <!-- <div class="text-center my-5 col-8 mx-auto">
      <img
        src="../../../assets/logo.png"
        alt="Ejaf Technology"
        class="logo w-75"
      />
    </div> -->
  </section>
</template>

<script>
//import blogs from "../../blog/Posts";
import axios from 'axios'
import {baseUrl} from './../../../service';
export default {
  name: "blog-section",
  data() {
    return {
   //   posts: Posts,
      blogs:[],
    };
  },
  mounted(){
    axios
      .get(`${baseUrl}/about-us`)
      .then(response => {
        this.blogs = response.data.data.blogs;
      })
  }
};
</script>

<style scoped>
small {
  font-size: 12px;
}
a {
  color: var(--red-color) !important;
}
.image-blog{
  height: 300px;
}
@media (min-width: 991px) {
  .blog .logo {
    max-width: 50% !important;
  }
}
</style>
