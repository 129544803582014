<template>
  <section>
    <div class="row">
    <div class="text-center my-5 col-8 mx-auto">
       <img
      :src="'https://test.it-tp.xyz/' + logoPath" alt="logo" class="logo w-75" />
      
      
       <!-- <img
        src="../../../assets/logo.png"
        alt="Ejaf Technology"
        class="logo w-75"
      />  -->
      
    </div>
  </div>
    <footer
    class="footer d-flex justify-content-center align-items-center flex-column text-center pt-2"
  >
    <span class="copyright">
      &copy; 2021 EJAFTEC. ALL RIGHTS RESERVED. DESIGNED BY
      <a href="#">MAZAJNET</a>
    </span>
    <ul class="mt-2">
      <li class="">
        <a href="#">
          <i class="fab fa-facebook-f"></i>
        </a>
      </li>
      <li class="mx-3">
        <a href="#">
          <i class="fab fa-twitter"></i>
        </a>
      </li>
      <li class="">
        <a href="#">
          <i class="fab fa-linkedin-in"></i>
        </a>
      </li>
    </ul>
  </footer>
  </section>
  
</template>

<script>
import {baseUrl} from './../../service';
import axios from 'axios'

export default {
  name: "footer-component",
  data(){
    return{
      logoPath:''      
    }
  },
  mounted(){
    axios 
      .get(`${baseUrl}/get-settings`)
      .then(response => {
        this.logoPath = response.data.data.settings.logo_path;
        console.log("AHA")
        console.log(response.data.data.settings)
      })
    
  }
};
</script>

<style scoped>
.footer {
  border-top: 1px solid #020f21;
}
.footer,
.footer a {
  color: rgba(255, 255, 255, 0.5) !important;
  transition: 0.3s all;
}
.footer a:hover {
  color: rgba(255, 255, 255, 1) !important;
}
.copyright a,
.copyright a:hover {
  color: var(--red-color) !important;
}
ul {
  padding-left: 0;
}
</style>
