<template>
  <div class="video-container position-relative top-0 start-0 w-100">
    <video class="position-absolute w-100" autoplay loop muted>
      <source src="../../assets/home/landing/landing.mp4" type="video/mp4" />
    </video>
  </div>
  <div class="container">
    <header-component />
    <section class="intro text-center headrSection" >
      <h2 class="text-capitalize mb-4">technology partner</h2>
      <h3 class="mb-4">{{mainText}}</h3>
      <p class="intro-desc">{{mainDescription}}</p>
      <description-component :desc="desc"  />
    </section>
    <services-section />
    <technological-section />
    <!-- <partners-section /> -->
    <section class="technological technologicalII text-center mt-5 mb-5 pt-3">
     <h2 class="mb-1">Technology Success</h2>
     <p>{{technologySuccess}}</p>
    </section>
  
    <section class="technological text-center mt-5 pt-3">
      <h2 class="mb-1">our partners</h2>
      <p>{{aboutUsText}}</p>
     </section>
 
    <allParteners />
    <ContactsSection />
    <BlogSection />
  </div>
</template>

<script>
import HeaderComponent from "../shared/Header.vue";
import BlogSection from "../shared/blog/Blog.vue";
import ContactsSection from "../shared/contacts/Contacts.vue";
import ServicesSection from "./services/Services.vue";
import TechnologicalSection from "./technological/Technological.vue";
import allParteners from "./allParteners/index.vue";


import {baseUrl} from './../../service';
import axios from 'axios'

export default {
  name: "home-component",
  data(){
   return{
    mainText:'',
    mainDescription:'',
    technologySuccess:'',
    aboutUsText:''
   }
  },
  components: {
    ServicesSection,
    TechnologicalSection,
    // PartnersSection,
    ContactsSection,
    BlogSection,
    HeaderComponent,
    allParteners
  },
  mounted(){
    axios 
      .get(`${baseUrl}/get-settings`)
      .then(response => {
        //this.values = response.data
        this.mainText = response.data.data.settings.main_text;
        this.mainDescription = response.data.data.settings.main_description
        this.technologySuccess = response.data.data.settings.technology_success_description
        this.aboutUsText = response.data.data.settings.about_us_text
        console.log(response.data)
        console.log(response.data.data.settings)
      })
    
  }
};
</script>

<style scoped>
video {
  object-fit: cover;
  z-index: -1;
  top: -90px;
  height: 100vh;
}
.technologicalII{
  margin-bottom: 160px !important;
}
.technological__desc {
  text-align: start !important;
  font-size: 14pt !important;
}

.headrSection{
  width: 75%;
}

@media (max-width:992px) {
  
.headrSection{
  width: 100%;
}

}
@media (min-width: 991px) {
  video {
    left: 50px;
  }  
}
</style>
